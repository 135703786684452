import { createMemo, Show } from 'solid-js';
import { useLocation, useSearchParams } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Link } from '@solidjs/meta';
import { Nav } from '../components/nav';
import { Footer } from '../components/footer';
import { useUser } from '../providers/user';
import { getBaseUrl } from '../modules/schema/base-url';
import type { ParentProps } from 'solid-js';

type Props = ParentProps;

export function BaseLayout(props: Props) {
	const location = useLocation();
	const [params] = useSearchParams();
	const user = useUser();

	const canonical = createMemo(() => {
		const paramLength = Object.keys({ ...params }).length;
		if (!location.pathname.endsWith('/') && !paramLength) {
			return undefined;
		}

		return new URL(location.pathname.replace(/\/$/, ''), getBaseUrl());
	});

	return (
		<>
			<Show when={canonical()}>{(url) => <Link rel="canonical" href={url().toString()} />}</Show>

			<div class="relative z-10 flex min-h-screen w-screen flex-col justify-stretch">
				<Nav />

				<main id="content" class="relative z-0 flex shrink-0 grow flex-col pb-6 md:pb-12">
					{props.children}
				</main>
				<footer class={twJoin('bg-white', user() ? 'shrink-0 pb-24 md:pb-4' : 'shrink-0 pb-4')}>
					<Footer />
				</footer>
			</div>
		</>
	);
}
