import { Show } from 'solid-js';
import { useIsEnabled } from '@troon/analytics';
import { NavDotCom } from './nav/dot-com';
import { NavDigital } from './nav/digital';

export function Nav() {
	const dotComTakeover = useIsEnabled('dot-com-takeover');

	return (
		<Show when={dotComTakeover} fallback={<NavDigital />}>
			<NavDotCom />
		</Show>
	);
}
