import { addBreadcrumb, breadcrumbsIntegration, captureException, init } from '@sentry/solidstart';
import { solidRouterBrowserTracingIntegration } from '@sentry/solidstart/solidrouter';
import { getAccessibleName } from 'accname';
import { getConfigValue } from '../config';

export function initSentry() {
	const dsn = getConfigValue('SENTRY_DSN');
	if (!dsn) {
		return;
	}

	const sampleRate =
		getConfigValue('ENVIRONMENT') === 'production'
			? parseFloat(window.__FLAGS__ ? ((window.__FLAGS__['web-profiling'] as string) ?? '0.1') : '0.1')
			: 0;

	init({
		dsn,
		environment: getConfigValue('ENVIRONMENT', 'development'),
		integrations: [
			solidRouterBrowserTracingIntegration(),
			breadcrumbsIntegration({
				history: true,
				sentry: true,
			}),
		],
		tracePropagationTargets: [/^https:\/\/(www|staging|localhost)\.?troon\.digital/],
		tracesSampleRate: sampleRate,
		profilesSampleRate: sampleRate,
		release: import.meta.env.VITE_GIT_SHA,
		ignoreErrors: [/transition was (aborted|skipped)/i, /view transition/, 'Attempted to assign to readonly property'],
		beforeBreadcrumb(breadcrumb, hint) {
			if (hint?.event.currentTarget) {
				const name = getAccessibleName(hint.event.target);
				if (name) {
					breadcrumb.message = `"${name}" (${breadcrumb.message})`;
				}
			}
			return breadcrumb;
		},
	});

	window.addEventListener('vite:preloadError', (e) => {
		addBreadcrumb({ message: 'Caught dynamic import preload error, will reload window…' });
		captureException(e.payload);
		window.location.reload();
	});
}
