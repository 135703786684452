import { Show } from 'solid-js';
import { useIsEnabled } from '@troon/analytics';
import { FooterDotCom } from './footer/dot-com';
import { FooterDigital } from './footer/digital';

export function Footer() {
	const dotComTakeover = useIsEnabled('dot-com-takeover');

	return (
		<Show when={dotComTakeover} fallback={<FooterDigital />}>
			<FooterDotCom />
		</Show>
	);
}
