import { mount, StartClient } from '@solidjs/start/client';
import { AnalyticsProvider } from '@troon/analytics';
import { createClient } from '@troon/analytics/client';

import { ErrorBoundary } from './components/error-boundary';
import { getConfigValue } from './modules/config';
import { polyfill } from './providers/polyfill';
import { initSentry } from './modules/sentry/browser';

const ua = window.navigator.userAgent.toLowerCase();
const isDisallowedClient = ua.includes('lighthouse') || ua.includes('bingbot/') || ua.includes('googlebot/');

// Polyfill for Object.hasOwn – we get hit by lots of old bots that need this
if (!Object.hasOwn) {
	Object.defineProperty(Object, 'hasOwn', {
		value: function (obj: Record<string, unknown>, prop: string) {
			return Object.prototype.hasOwnProperty.call(obj, prop);
		},
		configurable: true,
		enumerable: false,
		writable: true,
	});
}

const posthog =
	!isDisallowedClient && getConfigValue('POSTHOG_ENABLED')
		? createClient(getConfigValue('POSTHOG_WRITE_KEY'), window.__USERID__, window.__FLAGS__ ?? {})
		: undefined;

if (!isDisallowedClient && getConfigValue('SENTRY_ENABLED')) {
	initSentry();
}

window.addEventListener('vite:preloadError', () => {
	window.location.reload(); // for example, refresh the page
});

async function startup() {
	await polyfill();

	mount(() => {
		return (
			<ErrorBoundary>
				<AnalyticsProvider posthog={posthog} flags={window.__FLAGS__} overrides={window.__FLAG_OVERRIDES__}>
					<StartClient />
				</AnalyticsProvider>
			</ErrorBoundary>
		);
	}, document.getElementById('app')!);
}

startup();
